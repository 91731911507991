<template>
	<div :data-id="item.id" :class="{'parent': true, 'danger': item.quantite === 0}">
		<span class="name" style="flex-grow: 1;">{{ item.nom }}</span>
		<div class="prix">
			<b>{{ money(item.prix_vente) }} Fbu</b> <span>| dispo:</span>
			<span class="dispo">
				{{ item.quantite || 0 }} {{ item.unite_sortante }}
			</span>
		</div>
		<div class="buttons">
			<button class="bg-blue" @click="checkValidation('decrease')">-</button>
			<input 
				v-if="editable" 
				type="number" 
				class="item_qtt"
				v-model="qtt" 
				@keydown.stop 
				:max="item.quantite" 
				min="0"
			>
			<label v-else @click="checkValidation('edit')">{{ qtt }}</label>
			<button class="bg-blue" @click="checkValidation('increase')">+</button>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	props: {
		item: { 
			type: Object, 
			required: true 
		}
	},
	data() {
		return {
			cart: this.$store.state.cart, 
			editable: false,
		};
	},
	methods: {
		async checkValidation(action) {
			try {
				let kiosk_id = this.getActiveKiosk().id;
				let link = `${this.url}/stock/?produit__kiosk=${kiosk_id}&produit__id=${this.item.id}`;
				
				let response = await axios.get(link, this.headers);
				let stocks = response.data.results;

				if (!stocks || stocks.length === 0) {
					alert("Aucun stock trouvé pour ce produit.");
					return;
				}

				// Vérifiez si un stock n'est pas validé
				let invalidStock = stocks.find(stock => stock.validated_by === null);
				if (invalidStock) {
					alert("Veuillez valider tous les stocks associés à ce produit avant de continuer.");
					return;
				}

				// Gestion des actions
				if (action === 'decrease') {
					this.decreaseFromStocks(stocks);
				} else if (action === 'increase') {
					this.increaseQtt();
				} else if (action === 'edit') {
					this.enableEdition();
				}
			} catch (error) {
				this.displayErrorOrRefreshToken(error, () => this.checkValidation(action));
			}
		},
		increaseQtt() {
			this.cart.add(this.item);
		},
		decreaseFromStocks(stocks) {
			let remainingQuantity = 1; // Quantité à retirer
			let updatedStocks = [];

			// Parcours des stocks pour gérer la réduction de quantité
			for (let stock of stocks) {
				if (remainingQuantity <= 0) break;

				if (stock.quantite >= remainingQuantity) {
					stock.quantite -= remainingQuantity;
					remainingQuantity = 0;
				} else {
					remainingQuantity -= stock.quantite;
					stock.quantite = 0;
				}

				updatedStocks.push(stock);
			}

			if (remainingQuantity > 0) {
				alert("Quantité insuffisante dans les stocks disponibles.");
			} else {
				this.$store.commit('updateStocks', updatedStocks);
				this.cart.decrease(this.item.id); // Mettez à jour le panier après modification du stock
			}
		},
		enableEdition() {
			if (this.item.quantite !== 0) this.editable = true;
		}
	},
	computed: {
		qtt: {
			get() {
				return this.cart.getQuantite(this.item.id);
			},
			set(value) {
				if (value.length === 0) return;
				this.cart.add(this.item, value);
			}
		}
	}
};
</script>

<style scoped>
/* Ajout de styles existants */
</style>

<style scoped>
*{
	color: #eee; 
}
.img{
	padding: 5px;
	color:#aaa;
}
.danger *{
	color: #444!important;
	border-color: var(--primary);
	cursor: not-allowed;
}
.danger .bg-blue:hover{
	background-color: var(--primary);
	color:inherit;
}
.danger .buttons{
	border-top: 1px solid var(--overlay-color);
}
.parent{
	background-color: var(--primary-light);
    padding: 3px;
	text-align: center;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--primary-light);
	/*max-width: 200px;*/
	height: 100%;
	max-height: 100px;
}
.name{
	font-size: .8em;
	font-weight: 700;
	width: 100%;
}
.prix{
	font-size: .8em;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 4px 0;
	color: white!important;
}
.prix b{
	color: white;
}
.qtt{
	font-size: .7em;
	margin-bottom: 2px;
}
.buttons{
	display: flex;
	margin-top: 2px;
	border-top: 1px solid black;
	width: 100%;
}
button{
	border: 0;
	background-color: var(--primary-light);
}
.bg-blue{
	width: 35%;
	padding: 3px;
	margin: 0;
	text-align: center;
	border-radius: 0;
	font-weight: bold;
	font-size: 20px;
}
label{
	flex-grow: 1;
	background-color: white;
	color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-top: 0;
}
.bg-blue:hover{
	background-color: var(--primary);
	color: white;
}
.item_qtt{
	text-align: center;
	width: 70px;
    border-top: 0;
    padding-right: -5px;
}
.dispo{
	color: #fff;
	padding: 2px 5px;
	background-color: var(--primary);
	font-weight: bold;
	font-size: .9em;
	display: inline-block;
	max-width: 90px;
	overflow: hidden;
	margin-bottom: -4px;
}
.danger .dispo{
	background-color: #0000;
}
</style>