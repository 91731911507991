<template>
    <div class="facture_container">
        <div class="facture_header">
            <div class="facture_left">
                <h3>BIOTECH PHARMA</h3>
                <!-- <span>MAGASIN VENTE</span> -->
                <div>
                    <span>NIF : {{ '9876543' }} | RC : {{ '9876543' }}</span>
                </div>
                <span>Tel : +257 2280531</span> 
                <span>Assujeti à la TVA : {{ 'NON' }}</span>
                <span>Centre Fiscale : {{ 'Lorem Ipsum' }}</span>
                <span>Compte bancaire : {{ 'NON' }}</span>
                <span>Adresse : Commune Mukaza, Rohero I, avenue du chaussée PRINCE LOUIS RWAGASORE</span>
            </div>
            <div class="facture_right universal">
                <h3>CLIENT</h3>
                <span>Nom : {{ 'NTUNZWENIMANA Yanick' }}</span>
                <span>Telephone : {{ '87654345' }}</span>
                <span>NIF : {{ '8799654345' }}</span>
                <span>TYPE DE VENTE : {{ 'Cash' }}</span>
            </div>
        </div>
        <div>
            <div class="client">
                <span>CLIENT : HOPITAL KIRUNDO</span>  <span>DATE : {{ date_time }} </span>
            </div>
            <h4>FACTURE N° {{'76543.53 Du'}} {{ date_time }}</h4>
        </div>
        <div class="facture_table_container">
            <table>
                <!-- <tr><h3>DETAILS DE LA FACTURE</h3></tr> -->
                <tr>
                    <th>N°</th>
                    <th>DESIGNATION</th>
                    <th>EXPIRATION</th>
                    <th>QTE</th>
                    <th>P.V.U</th>
                    <th>P.V.T</th>
                </tr>
                <tr>
                    <td colspan="5">
                        <h3>TOTAL</h3>
                    </td>
                    <td><h3>{{ money(10090990) }}</h3></td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    props : {
        commade : {
            type : Array,
            Required : true
        }
    },
    computed: {
        date_time() {
            const now = new Date();
            const date = now.toLocaleDateString();
            return `${date}`;
        }
    }
};
</script>
<style src="./facture.css" scoped></style>