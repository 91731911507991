import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faShoppingCart, faBox, faBoxOpen, faChartBar, faUser, faBars,
  faSortDown, faExclamationTriangle, faTachometerAlt, faBell, faQrcode,
  faLongArrowAltRight, faMoneyBillAlt, faDatabase, faSmileWink,
  faSearch, faTimes, faPlus, faList, faBalanceScale, faTrashAlt,
  faFileInvoice, faDownload, faUpload, faFolderOpen, faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons'

library.add({
  faShoppingCart, faBox, faBoxOpen,  faChartBar, faUser, faBars,
  faSortDown, faExclamationTriangle, faTachometerAlt, faBell, faQrcode,
  faLongArrowAltRight, faMoneyBillAlt, faDatabase, faSmileWink,
  faSearch, faTimes, faPlus, faList, faBalanceScale, faTrashAlt,
  faFileInvoice, faDownload, faUpload, faFolderOpen, faHandHoldingUsd
})

Vue.component('fa', FontAwesomeIcon)

window.axios = axios;

Vue.mixin({
  methods: {
    isNumeric(x) {
      let str = x.toString();
      if (str.match(/^[0-9]+$/)) return true;
      return false;
    },
    logOut(x) {
      if(confirm("Voulez-vous vraiment deconnecter?")){
        this.$store.state.user = null
        this.$store.state.active_kiosk = null
      }
    },
    money(x) {
      let cash = parseFloat(x).toFixed(0)
      if(!x) return "0";
      return cash.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    cleanString(str){
      if (!str) return "";
      if(typeof(str)=='object'){
        let string = ""
        for( let [clef, valeur] of Object.entries(str)){
          if(typeof(valeur)=='object'){
            let child = ""
            for( let [key, value] of Object.entries(valeur)){
              child += `- ${key}: ${value}. `
            }
            valeur = child;
          }
          string+=`${clef}: ${valeur}. `
        }
        return string;
      };
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
    },
    getGroupName(id){
      let group = this.$store.state.groups.find(x => x.id == id)
      return !!group? group.name:"-";
    },
    getGroupId(name){
      let group = this.$store.state.groups.find(x => {
        return x.name.toLowerCase() == name.toLowerCase()
      })
      return !!group? group.id:-1;
    },
    userIs(personnel, group_id){
      let groups = personnel.user.groups;
      return groups.includes(group_id);
    },
    datetime(x) {
      if(!x) return "-"
      let date = new Date(x);
      return new Intl.DateTimeFormat(
        'en-GB',
        { dateStyle: 'short', timeStyle: 'short' }
      ).format(date)
    },
    // refreshToken(callback){
    //   let refresh = this.$store.state.user.refresh
    //   if(!refresh){
    //     this.$store.state.user = null;
    //     this.$store.state.active_kiosk = null;
    //   }
    //   axios.post(this.url+"/refresh/", {"refresh":refresh})
    //   .then((response) => {
    //     this.$store.state.user.access = response.data.access
    //     callback()
    //   }).catch((error) => {
    //     if (!!error.response && error.response.status == 401) {
    //       this.$store.state.user = null;
    //     } else {
    //       console.error(error)
    //     }
    //   })
    // },
    displayErrorOrRefreshToken(error, callback) {
      if (!!error.response) {
        if (error.response.status == 401) { 
          let refresh = this.$store.state.user?.refresh;
          if (!refresh) {
            this.$store.state.user = null;
            this.$store.state.active_kiosk = null;
            this.$router.push('/login');
            return;
          }
          axios.post(this.url + "/refresh/", { "refresh": refresh })
            .then((response) => {
              this.$store.state.user.access = response.data.access;
              if (typeof callback == "function") callback();
            })
            .catch((error) => {
              if (!!error.response && error.response.status == 401) {
                this.$store.state.user = null;
                // this.$router.push('/login');
              } else {
                console.error(error);
                this.displayNotification(error);
              }
            });
        } else {
          this.displayNotification(error);
        }
      }
    },
    displayNotification(error){
      if (Notification.permission === 'granted') {
        const notification = new Notification(this.cleanString(error.response.data))
      }
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            const notification = new Notification(this.cleanString(error.response.data))
          }
        })
      }
    },
    getActiveKiosk(){
      if(!this.$store.state.active_kiosk){
        if(!!this.active_user.kiosks && this.active_user.kiosks.length == 1){
          this.$store.state.active_kiosk = this.active_user.kiosks[0]
        }
      }
      return this.$store.state.active_kiosk
    }
  },
  computed:{
    active_user(){
      return this.$store.state.user;
    },
    active_fullname(){
      return `${this.active_user.first_name} ${this.active_user.last_name}`;
    },
    user_is_owner(){
      return !!this.getActiveKiosk() && this.active_user.owned.includes(this.getActiveKiosk().id)
    },
    base_url(){
      return this.$store.state.base_url;
    },
    url(){
      return this.base_url + this.$store.state.api;
    },
    headers(){
      return {
        headers:{
          "Authorization":"Bearer "+this.$store.state.user.access
        }
      }
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')