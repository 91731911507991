<template>
	<StatsLayout @search="search" @apply-filters="filter_data">
		<div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
			<button @click="printTable" style="margin-right: 10px">
				Imprimer
			</button>
		</div>
		
		<div class="table">
			<table>
				<thead>
					<tr>
						<th>id</th>
						<th>produit</th>
						<th>user</th>
						<th>date</th>
						<th class="right">qtt.</th>
						<th class="right">prix d'achat</th>
						<th>motif</th>
						<th>action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="perte in pertes" :key="perte.id">
						<td>{{ perte.id }}</td>
						<td>{{ perte.stock }}</td>
						<td>{{ perte.user }}</td>
						<td>{{ datetime(perte.date) }}</td>
						<td class="right">{{ perte.quantite }}</td>
						<td class="right">{{ money(perte.prix)}} FBu</td>
						<td>{{ perte.details }}</td>
						<td v-if="!perte.validated_by">
							<button @click="valider(perte)">
								accepter
							</button>
							<button @click="supprimer(perte)">
								refuser
							</button>
						</td>
						<td v-else>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
								<path d="M16 8a8 8 0 1 0-16 0 8 8 0 0 0 16 0zM6.003 11.803l-3.1-3.1 1.2-1.2 1.9 1.9 5-5 1.2 1.2-6.3 6.3z"/>
							</svg>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="5"></th>
						<th class="right">{{ money(
							pertes.reduce((acc, x) => acc + x.prix, 0)
						)}} FBu </th>
						<th></th>
						<th class="right">
							<!-- <button>tout accepter</button>
							<button>tout refuser</button> -->
						</th>
					</tr>
				</tfoot>
			</table>
		</div>
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout"

export default {
	components: { StatsLayout },
	data() {
		return {
			pertes: this.$store.state.pertes
		}
	},
	watch: {
		"$store.state.pertes"(new_val) {
			this.pertes = new_val
		}
	},
	methods: {
		filter_data(arg){
			this.fetchData(arg.date)
		},
		search(keyword) {
			this.pertes = this.$store.state.pertes.filter(x => {
				return JSON.stringify(x).toLowerCase().includes(keyword.toLowerCase())
			})
		},
		supprimer(item) {
			if (confirm("Voulez-vous vraiment annuler cette perte?")) {
				axios.delete(`${this.url}/perte/${item.id}/`, this.headers)
					.then((response) => {
						let index = this.$store.state.pertes.indexOf(item)
						this.$store.state.pertes.splice(index, 1)
					}).catch((error) => {
						this.displayErrorOrRefreshToken(error, () => this.supprimer(item))
					});
			}
		},
		valider(item) {
			let username = this.active_user.username
			if(prompt(`pour accepter ce versement tapez ${username}:`) == username){
				axios.get(`${this.url}/perte/${item.id}/valider/`, this.headers)
					.then((response) => {
						for (let key of Object.keys(item)) {
							item[key] = response.data[key]
						}
					}).catch((error) => {
						this.displayErrorOrRefreshToken(error, () => this.supprimer(item))
					});
			}
		},
		fetchData(date = '') {
			let link = ""
			let kiosk_id = this.getActiveKiosk().id
			if (!this.next) {
				link = this.url + `/perte/?kiosk=${kiosk_id}${date ? `&date=${date}` : ''}`;
			} else {
				link = this.next
			}
			axios.get(link, this.headers)
				.then((response) => {
					this.$store.state.pertes.push(...response.data.results)
					if (response.data.next.length > 0) {
						this.next = response.data.next
						this.fetchData()
					} else {
						this.next = null
					}
				}).catch((error) => {
					this.displayErrorOrRefreshToken(error, this.fetchData)
				});
		},
		printTable() {
			let printContent = `
				<table>
					<thead>
						<tr>
							<th>id</th>
							<th>produit</th>
							<th>user</th>
							<th>date</th>
							<th class="right">qtt.</th>
							<th class="right">prix d'achat</th>
							<th>motif</th>
						</tr>
					</thead>
					<tbody>
			`;

			this.pertes.forEach(perte => {
				printContent += `
					<tr>
						<td>${perte.id}</td>
						<td>${perte.stock}</td>
						<td>${perte.user}</td>
						<td>${this.datetime(perte.date)}</td>
						<td class="right">${perte.quantite}</td>
						<td class="right">${this.money(perte.prix)} FBu</td>
						<td>${perte.details}</td>
					</tr>
				`;
			});

			printContent += `
					</tbody>
				</table>
			`;

			const printWindow = window.open('', '', 'width=800,height=600');
			printWindow.document.write('<html><head><title>Historique des pertes</title>');
			printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; text-align: left; } .right { text-align: right; }</style>');
			printWindow.document.write('</head><body>');
			printWindow.document.write(printContent);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	},
	mounted() {
		if (this.$store.state.pertes.length < 1) {
			this.fetchData()
		}
	}
};
</script>

<style scoped>
</style>
